<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 설비 목록 -->
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :selection="isMultiple ? 'multiple' : 'none'"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :checkClickFlag="false"
          :hideBottom="true"
          :isExcelDown="false"
          rowKey="minEquipmentCheckId"
          checkDisableColumn="disable"
          @rowClick="rowClick"
        >
          <!-- @rowClick="rowClick" -->
          <template slot="table-button">
            <q-btn-group outline >
              <q-btn
                v-if="editable && !disabled && isMultiple"
                icon="help"
                color="deep-purple-6"
                text-color="white"
                class="custom-btn"
                align="center"
                round>
                <q-tooltip anchor="bottom left" self="top left">
                  <div class="tooltipCustomTop">
                    <!-- 버튼설명 -->
                    {{$language('버튼설명')}}
                  </div>
                  <div class="tooltipCustom">
                    <!-- o 선택완료 : 체크박스를 선택하여 점검완료 하는 버튼입니다. -->
                    {{$language('o 선택완료 : 체크박스를 선택하여 점검완료 하는 버튼입니다.')}}
                  <br>
                    <!-- o 일괄저장 : 설비목록과 설비별 점검항목, 개선관리를 일괄적으로 저장하는 버튼입니다. -->
                    {{$language('o 일괄저장 : 설비목록과 설비별 점검항목, 개선관리를 일괄적으로 저장하는 버튼입니다.')}}
                  <br>
                  </div>
                </q-tooltip>
              </q-btn>
              <!-- 선택완료 -->
              <c-btn v-if="editable && !disabled && isMultiple" :showLoading="false" label="선택완료" icon="save" color="blue"  @btnClicked="resultComplete" />
              <!-- 완료 -->
              <c-btn v-if="editable && !disabled && !isMultiple" :showLoading="false" label="완료" icon="check" color="blue"  @btnClicked="resultComplete2" />
              <!-- 일괄저장 : 저장 -->
              <c-btn
                v-if="editable && !disabled"
                :url="updateUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="PUT"
                :label="isMultiple ? '일괄저장' : '저장'"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'sopMocName'">
              <q-btn
                class="tableinnerBtn tableinnerUserSearch"
                :class="{'tableinnerUserSearch-left': true,  'tableinnerUserSearch-right': true }"
                flat
                :editable="editable"
                :disable="props.row.disable"
                color="blue-6"
                :label="props.row.sopMocName"
                icon="search"
                @click.stop="linkClick(props.row)" />
            </template>
            <template v-if="col.name === 'complete' && isMultiple">
              <q-btn
                class="glossy"
                round 
                size="sm"
                :editable="editable"
                :disable="props.row.disable"
                color="blue-6"
                icon="save"
                @click.stop="resultComplete3(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :result.sync="result"
            :imprTabInfo.sync="imprTabInfo"
            :research.sync="research"
            :isMultiple="isMultiple"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'equipment-result-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
        equipmentCd: '',
        minEquipmentCheckId: '',
        checkDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        sopMocName: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
        equipmentImproveModels: []
      },
      tab: 'resultItemTab',
      tabItems: [
        // 설비별 점검항목
        { name: 'resultItemTab', icon: 'list', label: '설비별 점검항목', component: () => import(`${'./equipmentResultItemTab.vue'}`) },
        // 개선관리
        { name: 'imprTab', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },
      ],
      grid: {
        columns: [],
        data: [],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      updateMode: false,
      research: {
        impr: '',
        item: '',
      },
    };
  },
  computed: {
    disabled() {
      let count = 0;
      this.$_.forEach(this.grid.data, _item => {
        if(_item.disable) count++;
      })
      return count === this.grid.data.length;
    },
    isOld() {
      return Boolean(this.popupParam.minEquipmentCheckId);
    },
    isMultiple() {
      return this.popupParam.selectData.length > 0;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.minEquipmentCheckId, // 
        ibmTaskTypeCd: 'ITT0000055',
        ibmTaskUnderTypeCd: 'ITTU000080',
        requestContents: this.result.equipmentName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: selectConfig.sop.min.equipment.result.list.url,
          param: {
            minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
            imprFlag: true,
          },
          title: '점검항목 별 개선사항 목록', // 점검항목 별 개선사항 목록
          merge: [
            { index: 0, colName: 'minEquipmentTypeCheckItemId' },
            { index: 1, colName: 'minEquipmentTypeCheckItemId' },
            { index: 2, colName: 'minEquipmentTypeCheckItemId' },
            { index: 3, colName: 'minEquipmentTypeCheckItemId' },
            { index: 4, colName: 'minEquipmentTypeCheckItemId' },
            { index: 5, colName: 'minEquipmentTypeCheckItemId' },
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              // 점검항목
              label: '점검항목',
              align: 'center',
              style: 'width:90px',
              sortable: false,
              fix: true,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              // 점검결과
              label: '점검결과',
              align: 'left',
              style: 'width:100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'checkWayName',
              field: 'checkWayName',
              // 검사방법
              label: '검사방법',
              align: 'center',
              style: 'width:100px',
            },
            {
              name: 'judgmentResultTypeName',
              field: 'judgmentResultTypeName',
              // 적합/부적합
              label: '적합/부적합',
              align: 'left',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              // 판정기준
              label: '판정기준',
              align: 'left',
              style: 'width:350px',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              // 점검시 안전사항
              label: '점검시 안전사항',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'research.item'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.updates.url;
      this.updateItemUrl = transactionConfig.sop.min.equipment.result.updateItem.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.completes.url;
      // code setting
    
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.selectData.length > 0) {
        this.grid.data = [];
        this.popupParam.selectData.sort(
          function(a,b){
            return (Number(a.equipmentTypeCd.match(/(\d+)/g)[0]) - Number((b.equipmentTypeCd.match(/(\d+)/g)[0])));
          }
        )
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.$format(this.getUrl, _item.minEquipmentCheckId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data)
            this.setHeader();
            this.rowClick(this.grid.data[0], 'temp')
          },);
        })
      }  else {
        if (this.popupParam.minEquipmentCheckId) {
          this.grid.data = [];
          this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentCheckId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data);
            this.setHeader();
            this.rowClick(this.grid.data[0], 'temp')
          },);
        }
      }

    },
    setHeader() {
      if (this.popupParam.selectData.length > 0) {
        this.$comm.getComboItems('MIM_CHECK_RESULT_CD').then(_result => {
          this.chkResultItems = _result;
          this.grid.columns = [
            {
              name: 'equipmentTypeName',
              field: 'equipmentTypeName',
              // 설비유형
              label: '설비유형',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentCd',
              field: 'equipmentCd',
              // 설비코드
              label: '설비코드',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              // 설비명
              label: '설비명',
              style: 'width:120px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'equipmentCheckKindNm',
              field: 'equipmentCheckKindNm',
              // 점검유형
              label: '점검유형',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'sopMocName',
              field: 'sopMocName',
              // MOC번호
              label: 'MOC번호',
              align: 'center',
              style: 'width:180px',
              sortable: false,
              type: "custom",
            },
            {
              name: 'checkScheduleUserName',
              field: 'checkScheduleUserName',
              // 점검예정자
              label: '점검예정자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkScheduleDate',
              field: 'checkScheduleDate',
              // 점검예정일
              label: '점검예정일',
              style: 'width:100px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkUserName',
              field: 'checkUserName',
              // 실제점검자
              label: '실제점검자',
              align: 'center',
              style: 'width:100px',
              setHeader: true,
              required: true,
              type: 'user',
              userId: 'checkUserId',
              sortable: false,
            },
            {
              name: 'checkDate',
              field: 'checkDate',
              required: true,
              setHeader: true,
              // 실제점검일
              label: '실제점검일',
              style: 'width:100px',
              type: 'date',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkResultCd',
              field: 'checkResultCd',
              // 점검결과
              label: '점검결과',
              required: true,
              align: 'center',
              style: 'width:80px',
              type: 'select',
              setHeader: true,
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'checkResultSummary',
              field: 'checkResultSummary',
              // 점검결과 요약
              label: '점검결과 요약',
              style: 'width:250px',
              type: 'textarea',
              align: 'left',
              sortable: true
            },
            {
              name: 'complete',
              field: 'complete',
              // 점검완료
              label: '점검완료',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              type: "custom",
            },
          ]
        });
        this.grid.height = '400px';
      } else {
        this.$comm.getComboItems('MIM_CHECK_RESULT_CD').then(_result => {
          this.chkResultItems = _result;
          this.grid.columns = [
            {
              name: 'equipmentTypeName',
              field: 'equipmentTypeName',
              // 설비유형
              label: '설비유형',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentCd',
              field: 'equipmentCd',
              // 설비코드
              label: '설비코드',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              // 설비명
              label: '설비명',
              style: 'width:120px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'equipmentCheckKindNm',
              field: 'equipmentCheckKindNm',
              // 점검유형
              label: '점검유형',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'sopMocName',
              field: 'sopMocName',
              // MOC번호
              label: 'MOC번호',
              align: 'center',
              style: 'width:180px',
              sortable: false,
              type: "custom",
            },
            {
              name: 'checkScheduleUserName',
              field: 'checkScheduleUserName',
              // 점검예정자
              label: '점검예정자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkScheduleDate',
              field: 'checkScheduleDate',
              // 점검예정일
              label: '점검예정일',
              style: 'width:100px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkUserName',
              field: 'checkUserName',
              // 실제점검자
              label: '실제점검자',
              align: 'center',
              style: 'width:100px',
              required: true,
              setHeader: true,
              type: 'user',
              userId: 'checkUserId',
              sortable: false,
            },
            {
              name: 'checkDate',
              field: 'checkDate',
              required: true,
              // 실제점검일
              label: '실제점검일',
              style: 'width:100px',
              type: 'date',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkResultCd',
              field: 'checkResultCd',
              // 점검결과
              label: '점검결과',
              required: true,
              align: 'center',
              style: 'width:80px',
              type: 'select',
              setHeader: true,
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'checkResultSummary',
              field: 'checkResultSummary',
              // 점검결과 요약
              label: '점검결과 요약',
              style: 'width:250px',
              type: 'textarea',
              align: 'left',
              sortable: true
            },
          ]
        });
        this.grid.height = '200px';
      }
    },
    rowClick(row, col) {
      if (col.name === 'sopMocName') {
        this.linkClick(row);
      } else {
        this.result = row;
      }
    },
    linkClick(row) {
      this.popupOptions.title = "설비관련 MOC 검색"; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.row = row;
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.row.sopMocName = data[0].mocTypeName + ' / ' + data[0].mocNo
        this.row.sopMocId = data[0].sopMocId
      }
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          this.result.plantName = _item.plantName;
          this.result.deptNm = _item.deptNm,  
          this.result.chkUserNm = data.userNm ? data.userNm : '', 
          this.result.chkUserId = data.userId ? data.userId: '',
          this.result.safFacilityNm = null, 
          this.result.safFacilityCd = null, 
          this.result.equipmentType = _item.equipType, 
          this.result.equipmentCd = _item.equipCd, 
          this.result.equipmentNm = _item.equipNm,
          this.result.chkSchYmd = data.chkYmd ? data.chkYmd : '', 
          this.result.remark = '';
        })
      }
    },
    saveInspection() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.result.equipmentResultList, _item => {
        _item.editFlag = 'U';
      })
    },
    resultComplete() {
      let selectData = this.$refs['table'].selected;
      if (selectData && selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // '안내',
          message: '선택된 항목이 없습니다.', 
          type: 'warning',
        });
        return;
      }
      if (this.$comm.validTable(this.grid.columns, selectData)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)', // 점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.checkCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                _item.checkStatusCd = 'MCSC000015';
                _item.disable = true;
              })
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    resultComplete2() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)', // 점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.checkCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.grid.data = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    // 단일 점검완료
    resultComplete3(data) {
      this.rowClick(data, 'temp')
      if (!data.checkUserId || !data.checkResultCd || !data.checkDate) {
          window.getApp.$emit('ALERT', {
          title: '안내',
          // 필수 입력값을 입력해 주세요. [실제점검자, 실제점검일, 점검결과]
          message: '필수 입력값을 입력해 주세요. [실제점검자, 실제점검일, 점검결과]',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)', // 점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            data.checkCompleteFlag = 'Y';
            this.$http.url = transactionConfig.sop.min.equipment.result.complete.url;
            this.$http.type = 'PUT';
            this.$http.param = data;
            this.$http.request(() => {
              data.disable = true;
              data.checkStatusCd = 'MCSC000015';
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
        },
      });
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    },
  }
};
</script>
